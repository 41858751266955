<template lang="">
  <basetable
    v-if="!isForm"
    ref="basetable"
    :dataurl="dataurl"
    :fields="fields"
    :baseroute="baseroute"
    :is-delete="false"
    :is-edit="false"
    :is-add="false"
    :is-filter="false"
    :is-detail="false"
    :tombol_hide="true"
  >
    <template v-slot:buttonaddon>
      <b-button
        class="mr-1 mb-2"
        variant="success"
        @click="showForm"
      >
        Tambah User Aplikasi
      </b-button>
    </template>
    <template #cell(is_active)="data">
      <span
        class="badge "
        :class="data.item.is_active?'bg-success':'bg-warning'"
      >
        {{ data.item.is_active?'Aktif':'Tidak Aktif' }}
      </span>
    </template>
    <template #cell(action)="data">
      <b-button
        class="mr-1"
        size="sm"
        variant="info"
        @click="showDetail(data)"
      >
        <feather-icon
          icon="FolderIcon"
        />
      </b-button>
    </template>
  </basetable>
  <b-card v-else>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="8">
            <form-input
              v-model="username"
              label="Username"
              rules="required|email"
              placeholder="Isi dengan alamat email"
            />
            <form-input
              v-model="name"
              rules="required"
              label="Nama"
              placeholder="Isi dengan nama"
            />
            <form-v-select
              v-model="level"
              :selected="level"
              rules="required"
              label="Level"
              placeholder="Select Value"
              :options="optionLevel"
            />
            <form-input
              v-model="password"
              rules="required"
              label="Password"
              placeholder="Password"
              :type="'password'"
            />
            <form-input
              v-model="retype_password"
              rules="required"
              label="Re-Password"
              placeholder="Ulang Password"
              :type="'password'"
            />
          </b-col>
          <b-col cols="12">
            <hr>
            <b-form-group
              label-cols-md="3"
              label=""
            >
              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                type="button"
                @click.prevent="validationForm"
              >
                <span v-if="loading">
                  <b-spinner small />
                  Loading...
                </span>
                <span v-else>Simpan</span>
              </b-button>
              <b-button
                class="mr-1"
                size="sm"
                variant="warning"
                type="button"
                @click.prevent="hideForm"
              >
                <span>Batal</span>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>

import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import basetable from '@/views/base/BaseTableV4.vue'
import { BCard, BButton, BFormGroup, BRow, BCol, BForm, BSpinner } from 'bootstrap-vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
let vendor = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    ValidationObserver,
    basetable,
    BCard,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    FormInput,
    FormVSelect,
    BSpinner
  },
  data() {
    return {
      dataurl:'/vendor/'+vendor.contact_id+'/user',
      baseroute:"user",
      fields: [
        { key: 'name', label: 'Nama', sortable: true },
        { key: 'email', label: 'Username', sortable: true },
        { key: 'role.name', label: 'Level', sortable: true },
      ],
      optionLevel:[],
      username:"",
      name:"",
      password:"",
      retype_password:"",
      level:null,
      isForm:false,
      loading:false,
      user_id:null
    }
  },
  mounted(){
    this.fetchDataRole()
  },
  methods:{
    fetchDataRole(){
      this.$http.get('role').then(res=>{
        this.optionLevel = res.data.data
      })
    },
    showForm(){
      this.isForm = true
    },
    hideForm(){
      this.isForm = false
    },
    validationForm(){
      this.$refs.simpleRules.validate().then( success => {
        if(success){
          if(this.retype_password != this.password){
            return this.$bvToast.toast('Please correct form input password retype', {
              title: 'Password Error',
              solid: true,
              variant:'danger'
            })
          }

          this.loading = true
          let params = {
            name: this.name,
            email: this.username,
            username: this.username,
            password: this.password,
            level: this.level != null?this.level.name:null,
            role_id: this.level != null?this.level.id:null,
            is_active: true
          }
          if(!this.user_id)
            this.$http.post(this.dataurl, params)
            .then(()=>{
              this.successSubmit()
            })
            .catch(err=>{
              this.errorSubmit(err)
            })
            .finally(() => {this.loading = false})
          else
            this.$http.put(this.dataurl+'/'+this.$route.params.id+'/user/'+this.user_id, params)
            .then(()=>{
              this.successSubmit()
            })
            .catch(err=>{
              this.errorSubmit(err)
            })
            .finally(() => {this.loading = false})
        }else{
          return this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    showDetail(data){
      this.$router.push({name:'data-perusahaan-user-view', params:{id:data.item.id}})
    },
    successSubmit(){
      this.$refs.simpleRules.reset()
      this.hideForm()
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    }
  }
}
</script>
<style lang="">

</style>
