<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields-title="fieldsTitle"
      :fields-content="fields"
      :baseroute="baseroute"
      :isdelete="false"
      :is-action="true"
      @readData="showData"
    >
      <template v-slot:buttonaction>
        <b-button
          class="mr-1"
          variant="primary"
          type="button"
          @click.prevent="confirmPerubahan"
        >
          
          <span>Ajukan Perubahan Data</span>
        </b-button>
      </template>
      <template v-slot:infodetail>
        <div class="d-flex justify-content-start">
          <b-avatar
            class="img-fluid rounded mr-1"
            rounded="sm"
            variant="light-primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="TagIcon"
            />
          </b-avatar>
          <div class="d-flex flex-column ms-1">
            <div class="user-info mb-1">
              <p
                style="margin: 0;"
                class="card-text mr-1"
              >
                <b>{{ model?model.vehicle_count:0|numFormat }} Unit</b>
              </p>
              <p
                style="margin: 0;"
                class="card-text mr-1"
              >
                Kendaraan Aktif
              </p>
            </div>
          </div>
        </div>
      </template>
    </base-info>
  </b-card>
</template>

<script>

import {BCard, BButton, BAvatar} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfoSample.vue'
let vendor = JSON.parse(localStorage.getItem('userData'))
export default {
  components: {
    BCard,
    BaseInfo,
    BButton,
    BAvatar
  },

  data() {
    return {
      dataurl:'/vendor/'+vendor.contact_id,
      baseroute:"vendor",
      dataVendor: null,
      fieldsTitle: [
        { key: 'name', is_title:true},
        { key: 'code'},
        { key: 'approval_status', label: 'Status', type:'spankey', data:{0:'Pengajuan', 1:'Diterima', 2:'Ditolak'}},
      ],
      fields: [
        { key: 'address', label: 'Alamat', icon:'CompassIcon'},
        { key: 'phone', label: 'Handphone', icon:'PhoneIcon'},
        { key: 'email', label: 'Email', icon:'MailIcon'},
        { key: 'hr'},
        { key: 'cp_name', label: 'Contact Person', icon:'UserIcon'},
        { key: 'cp_number', label: 'No Hp CP', icon:'PhoneIcon'},
        { key: 'cp_email', label: 'Email CP', icon:'MailIcon'},
        { key: 'created_at', label: 'Tanggal Register', icon:'CalendarIcon', type:'date'},
        { key: 'updated_at', label: 'Persetujuan', icon:'CalendarIcon', type:'date'},
      ],
      approval_status : null,
      model:null
    }
  },
  mounted(){
    this.testing()
  },
  methods:{
    testing(){
      this.dataVendor = JSON.parse(localStorage.getItem('userData'))
      this.dataurl = '/vendor/'+this.dataVendor.contact_id
    },
    showData(result){
      this.model = result
      this.approval_status = result.approval_status
    },
    confirmPerubahan(){
      this.$router.push({name:'data-perusahaan-user-edit',params:{id:vendor.contact_id}})
    },
    confirmApproval(approve){
      const id = this.$route.params.id
      let params = {
        approval_status : 2,
        approval_note : 'ditolak'
      }

      if(approve){
        params.approval_status = 1
        params.approval_note = 'diterima'
      }

      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/approval', params)
              .then(() => {
                this.$refs.baseinfo.fetchData()
            })
          }
        })
    }
  }
}
</script>