<template>
  <div>
    <b-row>
      <b-col cols="9">
        <info />
      </b-col>
      <b-col cols="3">
        <b-card>
          <h5>Akses Aplikasi</h5>
          <br>
          Level : <span class="badge bg-info">{{ dataUser.role?dataUser.role.name:'-' }}</span> <br>
          Status : <span
            class="badge bg-info"
            style="margin-top: 2px;"
          >{{ (dataUser.is_active)?'Aktif':'Tidak Aktif' }}</span><br>
          Login Terakhir : {{ (dataUser.last_login_at != null)?dataUser.last_login_at:'-' }}<br>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <view-tab />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {BRow, BCol, BCard} from 'bootstrap-vue'

import Info from './include/Info.vue'
import ViewTab from './include/ViewTab.vue'
let vendor = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Info,
    ViewTab
  },
  data(){
    return{
      level:'-',
      last_login:'-',
      isUser:false,
      dataUser:{}
    }
  },
  mounted(){
    this.showData()
  },
  methods:{
    showData(){
      this.$http.get('vendor/'+vendor.contact_id).then(res=>{
        this.dataUser = res.data.user
      })
    }
  }
}
</script>
