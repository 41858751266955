<template lang="">
  <div>
    <b-button
      variant="success"
      class="mr-0"
      @click.prevent="showUpload()"
    >
      Upload Dokumen Baru
    </b-button>
    <hr>
    <div v-if="renderComp">
      <div
        v-for="(records, index) in data_field"
        :key="index"
        class="form-group"
      >
        <div class="row">
          <div class="col-md-5">
            <span class="mr-1"><feather-icon
              icon="FileTextIcon"
            /></span><a
              :href="records.url"
              target="_blank"
            >{{ records.name }}</a>
          </div>
          <div class="col-md-1">
            <b-button
              variant="danger"
              class="mr-0"
              title="Hapus"
              @click.prevent="confirmDelete(records)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </div>
        </div>
        <hr>
      </div>
    </div> 
    <b-modal
      id="modal-center"
      ref="my-modal-form"
      :title="title_modal"
      centered
      hide-footer
    >
      <div class="form-group">
        <label>Pilih Jenis Dokumen</label>
        <select
          v-model="data_form.file_type_id"
          class="form-control"
        >
          <option value="">
            Pilih Jenis Dokumen
          </option>
          <option
            v-for="(records, index) in data_doc"
            :value="records.id"
            :key="index"
          >
            {{ records.name }}
          </option>
        </select>
        <label>Pilih File</label>

        <b-form-file
          id="extension"
          v-model="datafile"
          accept=".jpg, .png, .pdf, .docx, .xlsx"
          placeholder="Tidak ada file yang dipilih"
          @change="upload"
        />
      </div>

      <div class="form-group mt-3 float-right">
        <b-button
          class
          variant="outline-danger mr-1"
          @click="hideModal"
        >
          Batal
        </b-button>
        <b-button
          variant="primary"
          @click.prevent="submitForm"
        >
          Simpan
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BModal,
  BFormFile,
  BButton
} from 'bootstrap-vue'

let vendor = JSON.parse(localStorage.getItem('userData'))
export default {
  components: {
    BButton,
    BModal,
    BFormFile,
  },
  props:{
    item_id: {
      type: String,
      default: '',
    },
    posturl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataurl:'/vendor/'+vendor.contact_id+'/file',
      baseroute:"spb",
      title:"Dokumen",
      tombol_hide:true,
      title_modal : null,
      renderComp:true,
      data_doc:[],
      data_field:[],
      datafile:null,
      data_form:{
            file_type_id:'',
            file:null,
            data_file:null,
      },
      fields: [
        { key: 'area.name', label: 'Judul File', sortable: true },
        { key: 'name', label: 'Tipe File', sortable: true },
      ],
    }
  },
  mounted(){
    this.getDokumen()
    this.getFile()
  },
  methods:{
    getDokumen(){
      this.$http.get('/file-type?filter[all_invoice]=true').then(res=>{
        this.data_doc = res.data.data
      })
    },
    getFile(){
      this.$http.get(this.dataurl).then(res=>{
        this.data_field = res.data.data
      })
    },
    showUpload(){
      this.title_modal = "Upload Dokumen"
      this.$refs['my-modal-form'].show()
    },
    hideModal(){
      this.$refs['my-modal-form'].hide()
    },
    upload(e){
       let files = e.target.files[0]
       this.data_form.data_file = files
    },
    submitForm(){
      let formData = new FormData()
      let filename = ""
      formData.append('file',this.data_form.data_file)
      this.$http.post('upload/file',formData).then(res=>{
        if(res.data){
              if(res.data.path.split("/")){
                filename = res.data.path.split("/").pop()
              }
              
              let params = {
                name: filename,
                file_type_id: this.data_form.file_type_id,
                file: res.data.path
              }
            this.data_form.file = res.data.path
            this.$http.post('/vendor/'+vendor.contact_id+'/file',params).then(()=>{
                this.getFile()
                this.$refs['my-modal-form'].hide()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Submit Success',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
            })
        }
      })
      .catch(err=>{
        this.errorSubmit(err)
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm(data.name != null ? 'Anda Ingin Menghapus Data '+data.name +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete('vendor/file/'+data.id)
              .then(() => {
                this.getFile()
                this.$toast({
                component: ToastificationContent,
                  props: {
                    title: 'Delete Success',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
            })
          }
        })
    },
  }
}
</script>
<style lang="">

</style>
